<template>
  <div>
    <v-data-table :headers="headers" :items="items" sort-by="no" dense>
      <template v-slot:top> </template>
      <template v-slot:item.no="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn color="success" @click="openDialog(item)">
          update status
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <dialog-update-status
      :dialog="dialog"
      :id="id"
      @save="updateStatus"
      @close="close"
    ></dialog-update-status>
  </div>
</template>

<script>
import DialogUpdateStatus from "./DialogUpdateStatus.vue";
export default {
  name: "fiscal-month",
  components: {
    DialogUpdateStatus,
  },
  data: () => ({
    items: [],
    dialog: false,
    id: 0,
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Month",
        value: "month",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("fiscalMonth/getFiscalMonthByYear", new Date().getFullYear())
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    openDialog(item) {
      this.id = item.id;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    updateStatus(params) {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("fiscalMonth/updateStatus", params)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
