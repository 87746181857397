<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Update Status</span>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="params.date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="params.date" @input="menuDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Status <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  flat
                  dense
                  outlined
                  background-color="white"
                  :items="listStatus"
                  v-model="params.status"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">
          save
        </v-btn>
        <v-btn color="error" @click="close">
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "dialog-update-status",
  props: {
    dialog: Boolean,
    id: Number,
  },
  data() {
    return {
      menuDate: false,
      params: {
        id: 0,
        date: moment().format("YYYY-MM-DD"),
        status: "Pending",
      },
      listStatus: ["Open", "Pending", "Closed"],
    };
  },
  methods: {
    save() {
      this.params.id = this.id;
      this.$emit("save", this.params);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
